import './Index.css';
import trianglify from 'trianglify';
import colorbrewer from 'colorbrewer';
import {createRef, useEffect} from "react";


function Index() {
  let canvasRef = createRef();

  useEffect(() => {
    const seed = Math.random().toString(36);
    const cellSize = Math.random() * 100 + 50;

    let keys = [
      "BuGn", "BuPu", "GnBu", "OrRd", "PuBu", "PuBuGn", "PuRd", "RdPu", "YlGn", "YlGnBu",
      "YlOrBr", "YlOrRd", "Blues", "Greens", "Greys", "Oranges", "Purples", "Reds", "Spectral"
    ];
    let color = colorbrewer[keys[keys.length * Math.random() << 0]];

    keys = Object.keys(color);
    color = color[keys[keys.length * Math.random() << 0]];
    console.log(color);

    const triangle_bg = () => {
      const pattern = trianglify({
        width: window.innerWidth,
        height: window.innerHeight,
        seed: seed,
        cellSize: cellSize,
        palette: {col: color}
      })
      pattern.toCanvas(canvasRef.current);
    }
    triangle_bg();
    window.addEventListener('resize', triangle_bg);

    for (let i = 0; i < 8; i++) {
      canvasRef.current.style.filter = "blur(" + i + "px)";
      setTimeout(() => {
        canvasRef.current.style.filter = "blur(" + i + "px)";
      }, i * 32);
    }
  }, []);

  return (
    <div className="home">
      <div id="bg-canvas-div">
        <canvas width={window.innerWidth} height={window.innerHeight} ref={canvasRef} id="bg-canvas" />
      </div>
      <div id="stars" />
      <div id="stars2" />
      <div id="stars3" />
      <h1 id="titletext">ashduino101</h1>
      <h2 id="subtitletext">Software developer, musician, tech enthusiast</h2>
      <p>this site is in progress pls ignore :3</p>

      <div id="sections-container">
        <div id="section-content">
          <div id="section-projects">
            <svg className="section-divider" width="100%" height="auto" version="1.1" viewBox="0 0 508 33.8667" xmlns="http://www.w3.org/2000/svg"><path d="m0 33.8667s11.8992-14.3968 36.4323-14.5501c79.0628-0.494143 61.7471 18.5291 94.8754 13.836 34.254-4.85255 70.9543-12.4042 118.1-11.8594 37.3783 0.431934 71.1075 11.321 93.8871 8.40043 22.8951-2.9354 77.9527-24.8562 108.217-21.7423 48.2239 4.96169 56.0852 25.9154 56.0852 25.9154h-1.72952" fill="#fff"/></svg>
            <div id="section-content-1">
              <h1 id="section-header-1">Projects</h1>
              <a href="https://webassetstudio.dumbserg.al/" target="#">WebAssetStudio</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
