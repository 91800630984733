import React, {useState} from "react";
import {initializeApp} from 'firebase/app';
import {getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import './MSDAuth.css';

const firebaseApp = initializeApp({
  apiKey: "AIzaSyBfmuDFtPYiBjuEPUxP067OSB611rlIGfs",
  authDomain: "msd-utilities-f8d9d.firebaseapp.com",
  projectId: "msd-utilities-f8d9d",
  storageBucket: "msd-utilities-f8d9d.appspot.com",
  messagingSenderId: "347205241632",
  appId: "1:347205241632:web:5987c424994bdae81ce545",
  measurementId: "G-FPHBDNC2VL"
});
const firebaseAuth = getAuth(firebaseApp);
const redirectURL = 'https://discord.com/api/oauth2/authorize' +
  '?client_id=888926754528428032' +
  '&redirect_uri=http://localhost:3000/msd_auth' +
  '&response_type=code' +
  '&scope=identify';

function removeParam(parameter) {
  let url = document.location.href;
  const urlparts = url.split('?');

  if (urlparts.length >= 2) {
    const urlBase = urlparts.shift();
    const queryString = urlparts.join("?");

    const prefix = encodeURIComponent(parameter) + '=';
    const pars = queryString.split(/[&;]/g);
    for (let i = pars.length; i-- > 0;) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }
    url = urlBase + '?' + pars.join('&');
    window.history.replaceState('', '', url);

  }
  return url;
}

function maybeRedirect() {
  if ((window.location.href.includes('msd_auth')) && (!new URL(window.location.href).searchParams.get('code'))) {
    window.location = redirectURL;
  }
}

function doLogin() {
  signInWithPopup(firebaseAuth, new GoogleAuthProvider()).then(user => {
    user.code = new URL(window.location.href).searchParams.get('code');
    console.log(user);
  })
}

function submitData(data) {
  const json = JSON.stringify(data);
  const x = new XMLHttpRequest();
  x.open('POST', 'https://msd.dumbserg.al');
}

maybeRedirect();

export default function MSDAuth() {
  const [error, setError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  return (
    <div id="msd-container">
      <div id="auth-form">
        <div id="logo" />
        <div id="auth-form-content">
          <h1>Sign in to authorize the bot</h1>
          <div id="error">{error}</div>
          <button id="submit" onClick={doLogin} disabled={buttonDisabled} style={buttonDisabled ? {
            backgroundColor: '#545454',
            color: '#8c8c8c',
            outline: '2px solid #545454'
          } : {}}>Login with Google</button>
          <div id="privacy-policy">
            <h3 id="policy-title">Privacy Policy</h3>
            <p>
              This bot uses external services for some data storage and processing. By clicking 'Login with Google', you
              agree to your name, student ID, Discord ID, and profile picture URL being stored. You
              must be at least 13 years of age to use this service. If it is found that you are violating any part
              of this clause, your data will be removed immediately, and proper action will be taken. You may request your data to be removed
              at any time.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
