import {Routes, Route, Router, BrowserRouter} from 'react-router-dom';
import Index from "./Index";
import MSDAuth from "./MSDAuth";


export default function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/msd_auth" element={<MSDAuth />} />
      </Routes>
    </BrowserRouter>
  )
}
